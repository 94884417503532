import React from 'react';
import {
  Card,
  Typography,
  Space,
  Divider,
  Button,
  Row,
  Col
} from "antd";
import { RightOutlined } from "@ant-design/icons";

const { Title, Text, Paragraph } = Typography;

const TicketChip = props => {

  const { ticketID, type, subtype, status, tenant, appartment, location, recuring, clickView, statusDisplayName } = props;

  return (
    <Card className={`chip-wrap ${status === 'initial_assessment' && 'new-chip'} ${status === 'issue_resolved' && 'success-chip'} ${status === 'ag_not_responsible' && 'success-chip'}`}>
      <Title level={5} className="mb-0">{type} - {subtype}</Title>
      <Space size={4} className="mb-1"><Text className="ag-success fontWeight600">#{ticketID}</Text>{recuring && (<><Divider type="vertical" className="ticket-sep" /><Text className="ag-error fontWeight600">Recurring Issue</Text></>)}</Space>
      <Row align="bottom">
        <Col flex="auto">
          <Paragraph className="ag-primary fontWeight600 mb-0">{tenant}</Paragraph>
          <Paragraph className="smallFont12 text-italic mb-0">{appartment}</Paragraph>
          <Paragraph className="smallFont12 text-italic mb-0">{location}</Paragraph>
          <Paragraph className="smallFont12 ag-lightblue mb-0">Status: {statusDisplayName}</Paragraph>

        </Col>
        <Col flex="60px">
          <Button className="p-0 ag-success fontWeight600" type="link" onClick={clickView}>View <RightOutlined /></Button>
        </Col>
      </Row>
    </Card>
  );
};

export default TicketChip;
