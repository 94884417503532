import React from "react";
import { Card, Button, Typography, Space, Avatar } from "antd";
import Tenant1 from "../../../assets/img/timg1.jpg";
import { UserOutlined } from "@ant-design/icons";
import { Link } from "react-router-dom";

const { Title, Text } = Typography;

const TenantCard = ({ data }) => {
  const { id, name, email } = data;

  return (
    <Card
      bordered={false}
    // actions={[
    //   <Button type="link" className="smallFont10">
    //     Update Tenants
    //   </Button>,
    //   <Button type="link" className="smallFont10">
    //     <Link to={`tenants-detail/${id}`}>View Profile</Link>
    //   </Button>,
    // ]}
    >
      <Space size={24} align="center" direction="vertical" className="w-100">
        <Avatar style={{ marginLeft: 16 }} size={72} src={Tenant1} />
        <Space direction="vertical" size={8} className="w-100" align="center">
          <Title level={5} className="m-0 normalFont18">
            {name}
          </Title>
          <Text className="m-0">{email}</Text>
        </Space>
      </Space>
      <Button className="w-100 mt-2">View Lease</Button>
    </Card>
  );
};

export default TenantCard;
