import * as action_types from "./constants";

const initialState: any = {
  cmsData: [],
  categoriesData: [],
  cmsDetail: [],
  appData: {},
};

export default (state = initialState, action) => {
  const { type, data } = action;

  switch (type) {
    case action_types.GET_CMS:
      return { ...state, cmsData: data };
    case action_types.GET_CONTENT_CATEGORIES:
        return { ...state, categoriesData: data };  
    case action_types.GET_CMS_DETAIL:
        return { ...state, cmsDetail: data };  
    case action_types.GET_APPLICATIONS:
      return { ...state, appData: data };
    default:
      return state;
  }
};
