import React from 'react';
import {
  Typography,
  Card,
} from "antd";

const TicketHeading = props => {
  const { heading, value } = props;
  const { Title, Text } = Typography;

  return (
    <Card bordered={false} style={{ borderRadius: 4 }}>
      <Title level={3} className="chips-head ag-primary">{heading} <Text>({value})</Text></Title>
    </Card>
  );
};

export default TicketHeading;
