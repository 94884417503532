import * as action_types from "./constants";

const initialState: any = {
  announcementList: [],
  announcementCount: [],
  buildingData: []
};

export default (state = initialState, action) => {
  const { type, data } = action;

  switch (type) {
    case action_types.GET_ANNOUNCEMENT_LIST:
      return { ...state, announcementList: data };
    case action_types.GET_ANNOUNCEMENT_COUNT:
      return { ...state, announcementCount: data };
    case action_types.GET_BUILDING_LIST:
      return { ...state, buildingData: data };
    default:
      return state;
  }
};
