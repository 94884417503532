import axios from "../../../../utils/axiosInceptor";
import * as action_types from "./constants";
import { adminService } from "../../../../configs/constants";

const landlord_properties = `${adminService}/admin/landlord/properties`;




export const getAllPropertiesList = (id, param = {}, search) => {
  return async (dispatch) => {
    const {
      data: { result },
    } = await axios.get(`${landlord_properties}?party_id=${id}&${search ? `asset_name=${search}` : ''}`, { params: param })
    dispatch({
      type: action_types.GET_ALLPROPERTIES_STATS,
      data: result,
    });
  };
};

export const downloadPropertyData = (assetID) => async (dispatch) => {
  const { data: { result }, } = await axios.get(`${adminService}/admin/getTicketsExportData?assetId=${assetID}`)
  dispatch({
    type: action_types.DOWNLOAD_DATA,
    data: result
  })
}

export const getPropertyID = (prID) => (dispatch) => {
  dispatch({
    type: action_types.PROPERTY_ID,
    data: prID
  })
}