import * as action_types from "./constants";

const initialState: any = {
  techData: [],
  assignedList: [],
  unassignedList: [],
  unassignedCount: 1,
  assignedCount:1,
  buildings: [],
};

export default (state = initialState, action) => {
  const { type, data } = action;
  
  switch (type) {
    case action_types.GET_ASSIGNED_LIST:
      return { ...state, assignedList: data };
    case action_types.GET_UNASSIGNED_LIST:
      return { ...state, unassignedList: data };
    case action_types.GET_UNASSIGNED_COUNT:
      return { ...state, unassignedCount: data };
    case action_types.GET_ASSIGNED_COUNT:
      return { ...state, assignedCount: data };
    case action_types.GET_BUILDING_LIST:
      return { ...state, buildings: data };
    default:
      return state;
  }
};