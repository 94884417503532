import { Col, Pagination, Row } from 'antd';
import React from 'react';
import TenantCard from '../../atoms/TenantCard';

const TenantList = props => {
  return (
    <Row gutter={[24, 24]}>
      {props.pagination &&
        <Col span={24}>
          <Pagination
            className="ag-property-text-pagination"
            total={props.data.length}
            showTotal={(total, range) =>
              `Showing ${range[1]}-${total} Tenants`
            }
            current={props.currentPage}
            onChange={(e) => props.onpageChange(e)}
          />
        </Col>
      }

      {props.data.map((item, index) => (
        <Col flex="0 0 20%" key={index}>
          <TenantCard
            data={item}
          />
        </Col>
      ))}

    </Row>
  );
};

export default TenantList;
