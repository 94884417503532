import * as action_types from "./constants";

const initialState = {
  pdcList: [
    // {
    //   id: 2,
    //   cheque_number: "000022",
    //   process_through: "PDC",
    //   payment_type: "PDC",
    //   lease_location: "91509-2-202",
    //   status: "VALIDATED",
    //   enable: 1,
    //   amount: "2000",
    //   item_start_date: "2020-09-01",
    //   item_end_date: "2021-10-01",
    // },
  ],
};
export default (state = initialState, action) => {
  const { type, data } = action;
  switch (type) {
    case action_types.GET_PDC:
      return { ...state, pdcList: data };
    default:
      return state;
  }
};
