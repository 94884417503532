import * as action_types from "./constants";
import axios from "../../../../utils/axiosInceptor";
import {adminService} from "../../../../configs/constants";

export const getPDC = (limit, page, value) => {
  return async (dispatch) => {
    let url = `${adminService}/admin/pdc-listing?limit=${limit}&page=${page}`;
    if(value){
      url = `${adminService}/admin/pdc-listing?limit=${limit}&lease_location=${value}`;
    }
    console.log('value', value, url)
    const response = await axios.get(url);
    dispatch({
      type: action_types.GET_PDC,
      data: response?.data?.result,
    });
  };
};
