import React from "react";
import { Row, Col, Pagination } from "antd";
import PropertyCard from "../../atoms/PropertyCard";

const PropertyList = (props) => {
  const { onExport, propertyData } = props
  const onMaintenance = (param) => {
    console.log("Maintenance", param);
  };
  const onTenant = (param) => {
    console.log("Tenant", param);
  };
  const onProperty = (param) => {
    console.log("Property", param);
  };

  console.log(props.data, "This is props");
  return (
    <Row gutter={[24, 24]}>
      {props.pagination && (
        <Col span={24}>
          <Pagination
            className="ag-property-text-pagination"
            total={props.count}
            showTotal={(total, range) =>
              `Showing ${range[1]}-${total} Properties`
            }
            defaultPageSize={6}
            current={props.currentPage}
            onChange={(e) => props.onpageChange(e)}
          />
        </Col>
      )}

      {props.data &&
        props.data.map((item, index) => (
          <Col span={12} key={index}>
            <PropertyCard
              property={item}
              exportAction={onExport}
              propertyData={propertyData}
              maintenanceAction={onMaintenance}
              tenantAction={onTenant}
              propertyAction={onProperty}
              onStatusChanged={props.onStatusChanged}
            />
          </Col>
        ))}



      {/* {props?.data?.map((item, index) => (
        <Col span={12} key={index}>
          <PropertyCard
            property={item}
            exportAction={onExport}
            maintenanceAction={onMaintenance}
            tenantAction={onTenant}
            propertyAction={onProperty}
          />
        </Col>
      ))} */}
    </Row>
  );
};

export default PropertyList;
