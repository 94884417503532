import axios from "../../../../utils/axiosInceptor";
import * as action_types from "./constants";
import { adminService } from "../../../../configs/constants";
const announcementList = `${adminService}/admin/announcements`;
const buildingList = `${adminService}/admin/getListOfAllBuildings`;

export const getAnnouncementList = (page = 1, filterdata) => {
  return async (dispatch) => {
    try {
      const {
        data: { result },
      } = await axios.get(announcementList + `?page=${page}&perPage=50&${filterdata ? `search=${filterdata}` : ''}`);
      dispatch({
        type: action_types.GET_ANNOUNCEMENT_LIST,
        data: result,
      });
    } catch (e) {
      console.log("Err", e);
    }
  };
};

export const getAnnouncementCount = (page = 1) => {
  return async (dispatch) => {
    try {
      const {
        data: { result },
      } = await axios.get(announcementList + `?page=${page}&perPage=50`);
      dispatch({
        type: action_types.GET_ANNOUNCEMENT_COUNT,
        data: result.count,
      });
    } catch (e) {
      console.log("Err", e);
    }
  };
};

export const getBuildingList = (page = 1) => {
  return async (dispatch) => {
    try {
      const {
        data: { result },
      } = await axios.get(buildingList);
      dispatch({
        type: action_types.GET_BUILDING_LIST,
        data: result,
      });
    } catch (e) {
      console.log("Err", e);
    }
  };
};