
import { TENANTS_LIST } from './constants'
const initialState = {
  tenantList: []
};
export default (state = initialState, action) => {
  const { type, data } = action
  switch (type) {
    case TENANTS_LIST: return { ...state, tenantList: data }
    default: return state

  }
}
