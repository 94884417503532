import React, { useState, useEffect, Fragment } from "react";
import { Row, Col, Card, Typography, Space, Button, Select, Skeleton } from "antd";
import TicketHeading from '../../atoms/TicketHeading'
import TicketChip from '../../atoms/TicketChip'

const Tickets = props => {
  const [tickets, setTickets] = useState([]);
  const [loader, setLoader] = useState(true)
  const { ticketlist, category, viewTicket, count, onCount, handleStatusChange, showStatusOption } = props;
  const { Title } = Typography;

  useEffect(() => {
    ticketlist && setTickets(ticketlist);
  }, [ticketlist]);

  useEffect(() => {
    let timer1 = setTimeout(() => setLoader(false), 15 * 1000);
    return () => {
      clearTimeout(timer1);
    };
  }, [ticketlist])

  return (
    <Row gutter={[24, 24]}>
      <Col span={24}>
        <TicketHeading heading={category} value={count} />
      </Col>
      <Col span={24}>
        <Card bordered={false} style={{ borderRadius: 4 }}>
          {showStatusOption && showStatusOption.length &&
            <Select
              bordered={true}
              onChange={handleStatusChange}
              size="large"
              options={showStatusOption}
              style={{ width: '100%' }}
              defaultValue="All"
            />
          }
          {tickets.length > 0 ? (
            <Space size={24} direction="vertical" className="w-100">

              <Card
                bordered={false}
                className="ag-nospace-body ag-noborder-head"
                style={{
                  height: 550,
                  overflowY: "auto",
                }}
              >

                <Space size={24} direction="vertical" className="w-100">
                  {tickets.map((item: any, index) => {
                    return (
                      <TicketChip
                        key={index}
                        ticketID={item.case_id}
                        type={
                          item.CaseCategoryClassification[0].CaseCategory
                            .category_type
                        }
                        subtype={
                          item.CaseCategorySecondaryClassification[0]
                            .CaseSubCategory.category_sub_type
                        }
                        status={
                          item.CaseStatus[item.CaseStatus.length - 1].CaseStatusType.ticket_status
                        }
                        statusDisplayName={
                          showStatusOption.find(element => element.value == item.CaseStatus[item.CaseStatus.length - 1].CaseStatusType.ticket_status)?.label
                        }
                        tenant={
                          // item.Asset.AssetRole[0].PartyRoleType.name
                          item.CaseRoleTenant?.length > 0
                            ? item.CaseRoleTenant[0]?.Party?.name
                            : ""
                        }
                        appartment={item.Asset.property_name}
                        location={item.location}
                        recuring={
                          item.ChildCase.length > 0 &&
                          item.ChildCase[0]?.parent_id
                        }
                        clickView={() => viewTicket(item)}>
                      </TicketChip>

                    )

                  })
                  }
                </Space>
              </Card>
              {tickets.length < count ? (
                <Button type="link" block onClick={onCount}>
                  View More
                </Button>
              ) : (
                <Button></Button>
              )}
            </Space>
          ) : (
            <Fragment>
              <Skeleton loading={loader} active paragraph />
              {!loader &&
                <Title
                  level={5}
                  className="highlight ag-primary mb-0 p-1 text-center"
                >
                  There are currently no {category === "New" && "new"}
                  {category === "Closed" && "closed"} tickets{" "}
                  {category === "In Progress" && "in progress"}
                </Title>}

            </Fragment>
          )}
        </Card>
      </Col>
    </Row>
  );
};

export default Tickets;
