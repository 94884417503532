import axios from "../../../../utils/axiosInceptor";
import * as action_types from "./constants";
import { adminService } from "../../../../configs/constants";

const assignBaseURL = `${adminService}/admin/getAllAssignedLandlord`;
const unassignBaseURL = `${adminService}/admin/getAllUnassignedLandlord`;
const adminBuildingURL = `${adminService}/admin/getListOfAllBuildings`;

export const getAssignedList = (page = 1) => {
  return async (dispatch) => {
    try {
      const {
        data: { result },
      } = await axios.get(assignBaseURL + `?perPage=50&page=${page}`);
      dispatch({
        type: action_types.GET_ASSIGNED_LIST,
        data: result.landlord,
      });
      dispatch({
        type: action_types.GET_ASSIGNED_COUNT,
        data: result.count,
      });
    } catch (e) {
      console.log("Err", e);
    }
  };
};

export const getUnAssignedList = (page = 1) => {
  return async (dispatch) => {
    try {
      const {
        data: { result },
      } = await axios.get(unassignBaseURL + `?perPage=50&page=${page}`);
      dispatch({
        type: action_types.GET_UNASSIGNED_LIST,
        data: result.landlord,
      });
      dispatch({
        type: action_types.GET_UNASSIGNED_COUNT,
        data: result.count,
      });
    } catch (e) {
      console.log("Err", e);
    }
  };
};

export const getBuildings = () => {
  return async (dispatch) => {
    try {
      const {
        data: { result },
      } = await axios.get(adminBuildingURL);
      dispatch({
        type: action_types.GET_BUILDING_LIST,
        data: result,
      });
    } catch (e) {
      console.log("Err", e);
    }
  };
};
