import axios from "../../../../utils/axiosInceptor";
import * as action_types from "./constants";
import { adminService } from "../../../../configs/constants";

const cmsList = `${adminService}/cms`;
const contentCategories = `${adminService}/cms/content-categories`;
const applicationURL = `${adminService}/access/getApplications`;

export const getCMS = (page = 1, filterdata) => {
  return async (dispatch) => {
    try {
      const {
        data: { result },
      } = await axios.get(`${cmsList}${filterdata ? `?search=${filterdata}` : ''}`);
      dispatch({
        type: action_types.GET_CMS,
        data: result,
      });
    } catch (e) {
      console.log("Err", e);
    }
  };
};

export const getContentCategories = (page = 1) => {
  return async (dispatch) => {
    try {
      const {
        data: { result },
      } = await axios.get(contentCategories);
      dispatch({
        type: action_types.GET_CONTENT_CATEGORIES,
        data: result,
      });
    } catch (e) {
      console.log("Err", e);
    }
  };
};

export const getCMSDetail = (id) => {
  return async (dispatch) => {
    try {
      const {
        data: { result },
      } = await axios.get(`${adminService}/cms/details/${id}`);
      dispatch({
        type: action_types.GET_CMS_DETAIL,
        data: result,
      });
    } catch (e) {
      console.log("Err", e);
    }
  };
};

export const getApplication = () => {
  return async (dispatch) => {
    try {
      const {
        data: { result },
      } = await axios.get(applicationURL);
      dispatch({
        type: action_types.GET_APPLICATIONS,
        data: result,
      });
    } catch (e) {
      console.log("Err", e);
    }
  };
};
