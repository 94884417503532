import axios from '../../../../utils/axiosInceptor'
import { propertyService } from '../../../../configs/constants'
import { TENANTS_LIST } from "./constants";


export const getTenantsByID = (assetID, param = {}, search) => async (dispatch) => {
  const url = `${propertyService}/property-landlord/${assetID}/tenants?`;
  const { data: { result }, } = await axios.get(`${url}&${search ? `search=${search}` : ''}`, { params: param })
  if (result) {
    dispatch({
      type: TENANTS_LIST,
      data: result?.data
    })
  }
}
