import * as action_types from "../constants";

const initialState = {
  // Add general Info
  generalInfoData: {},
  propertyDetailsData: {},
  propertyFeaturesData: {},
  getPropertyById: [],
  getSingleProperty: [],
};

export default (state = initialState, action) => {
  const { type, data } = action;
  switch (type) {
    // Add General Info
    case action_types.ADD_PROPERTY_GENERALINFO_DATA:
      return { ...state, generalInfoData: data };
    case action_types.ADD_PROPERTY_PROPERTY_DETAILS_DATA:
      return { ...state, propertyDetailsData: data };
    case action_types.ADD_PROPERTY_PROPERTY_FEATURES_DATA:
      return { ...state, propertyFeaturesData: data };
    case action_types.GET_PROPERTY_BY_ID:
      return { ...state, getPropertyById: data }
    case action_types.GET_SINGLE_PROPERTY:
      return { ...state, getSingleProperty: data }
    default:
      return state;
  }
};
