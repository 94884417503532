import React from "react";
import { Menu, Space, Typography, Tabs, Card } from "antd";
import { Link } from "react-router-dom";

const { Text } = Typography;
const { TabPane } = Tabs;

const notificationApi = [
  {
    title: "Nabeel has paid his processing fee!",
    type: "booking",
    status: "read",
  },
  {
    title: "Nabeel has shared his offer on Al-Muteena.",
    type: "offer",
    status: "unread",
  },
  {
    title: "Aziz has requested to visit Al-Muteena.",
    type: "offer",
    status: "read",
  },
  {
    title: "Ahmed has shared his documents with you.",
    type: "documents",
    status: "unread",
  },
  {
    title: "Ahmed has shared his documents with you.",
    type: "offer",
    status: "read",
  },
  {
    title: "New maintenance request from Chris.",
    type: "maintenance",
    status: "unread",
  },
];

const getmessage = (type) => {
  switch (type) {
    case "offer":
      return {
        message: "Click here to view the offer.",
        link: "/offer-by-tenant",
      };
      break;
    case "documents":
      return { message: "Click here to review their documents.", link: "#" };
      break;
    case "maintenance":
      return {
        message: "Click here to assign a technician.",
        link: "/technicians",
      };
      break;
    case "booking":
      return { message: "Complete the booking now.", link: "#" };
      break;
    default:
      break;
  }
};

const newNotification = notificationApi.filter(
  (item) => item.status === "unread",
);

const NotificationPanel = ({ notificationsData }) => {
  return (
    <Card bordered={false} className="ag-nospace-body">
      <Tabs defaultActiveKey="1" tabBarGutter={24}>
        {/* <TabPane tab={`New (${newNotification.length})`} key="1">
          <Menu className="notif-panel" selectable={false}>
            {newNotification.map((item, index) => (
              <Menu.Item key={index} className={`notif-item ${item.status === 'read' ? 'notify-read' : ''}`}>

                <Space direction="vertical" size={4}>
                  <Text className="ag-primary">{item.title}</Text>
                  <Text className="smallFont12"><Link className="ag-secondary" to={getmessage(item.type).link}>{getmessage(item.type).message}</Link></Text>
                </Space>

              </Menu.Item>
            ))
            }
          </Menu>
        </TabPane> */}
        <TabPane tab={`All (${notificationsData.length})`} key="2">
          <Menu className="notif-panel" selectable={false}>
            {notificationsData.map((item, index) => (
              <Menu.Item
                key={index}
                className={`notif-item ${
                  item.status === "read" ? "notify-read" : ""
                }`}
              >
                <Space direction="vertical" size={4}>
                  <Text className="ag-primary">{item.text || ""}</Text>
                  <Text className="smallFont12">
                    <Link
                      className="ag-secondary"
                      to={
                        item.type === "lead_item_id"
                          ? `/offer-by-tenant/${item.identifier_value}`
                          : `/booking-form/${item.identifier_value}`
                      }
                      onClick={() =>
                        window.localStorage.setItem(
                          "selectedOffer",
                          JSON.stringify({
                            identifier: item.identifier_value,
                            id: item.id,
                          }),
                        )
                      }
                    >
                      {item.text || ""}
                    </Link>
                  </Text>
                </Space>
              </Menu.Item>
            ))}
          </Menu>
        </TabPane>
      </Tabs>
    </Card>
  );
};

export default NotificationPanel;
