import * as action_types from "./constants";

const initialState = {
  mapData: [],
  stats: {
    propertyCount: 0,
    applicationCount: 0,
    maintenanceCount: 0,
  },
  propertiesCard: {},
  propertiesListO: {},
  propertiesListV: {},
  allpropertiesList: {},
  getPropertyID: 0,
  propertyIDB: 0
};

export default (state = initialState, action) => {
  const { type, data } = action;
  switch (type) {
    case action_types.GET_PROPERTIES_LATLONG:
      return { ...state, mapData: data };
    case action_types.GET_DASHBOARD_STATS:
      return { ...state, stats: data };
    case action_types.GET_PROPERTIES_LIST_O:
      return { ...state, propertiesListO: data };
    case action_types.GET_PROPERTIES_LIST_V:
      return { ...state, propertiesListV: data };
    case action_types.GET_PROPERTIES_CARD:
      return { ...state, propertiesCard: data };
    case action_types.GET_ALLPROPERTIES_STATS:
      return { ...state, allpropertiesList: data };
    case action_types.GET_PROPERTY_ID:
      return { ...state, getPropertyID: data }
    case action_types.DOWNLOAD_DATA:
      return { ...state, downloadFile: data }
    case action_types.PROPERTY_ID:
      return { ...state, propertyIDB: data }
    default:
      return state;
  }
};
